.blog-post-editor {
    background: white;
    padding: 0rem 1rem;
    margin-bottom: 5px;
    min-height: 600px;
}

.blog-post-editor-html {
    background: rgb(255, 241, 230);
    padding: 1rem;
    word-wrap: break-word;
    width: 100%;
}

.blog-post-editor-toolbar {
    position: sticky;
    top: 0;
    z-index: 2;
    border: none;
    margin: 0;
}