.blog-pagination .page-link {
    text-align: center;
    margin: 0.15rem;
    padding: 0.5rem 0.54rem;
    height: 2.15rem;
    width: 2.15rem;
    background-color: unset;
    border: none;
    border-radius: 50%;
}

.blog-pagination .page-item:not(.active) .page-link:hover {
    background-color: #f8d8bf;
    color: #f4832e;
}

.blog-pagination .page-item.disabled .page-link {
    background-color: unset;
}