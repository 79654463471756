@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon/icomoon.woff?lftppc') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e900";
}
.icon-billboard:before {
  content: "\e901";
}
.icon-brochure-hand:before {
  content: "\e902";
}
.icon-chart:before {
  content: "\e903";
}
.icon-clock-analog:before {
  content: "\e904";
}
.icon-door-exit:before {
  content: "\e905";
}
.icon-envelope:before {
  content: "\e906";
}
.icon-envolope-hand:before {
  content: "\e907";
}
.icon-location:before {
  content: "\e908";
}
.icon-location-map:before {
  content: "\e909";
}
.icon-mailbox:before {
  content: "\e90a";
}
.icon-phone:before {
  content: "\e90b";
}
.icon-phone-ring:before {
  content: "\e90c";
}
.icon-poster:before {
  content: "\e90d";
}
.icon-posters:before {
  content: "\e90e";
}
.icon-shape:before {
  content: "\e90f";
}
.icon-sheet-checkmark:before {
  content: "\e910";
}
.icon-sheets:before {
  content: "\e911";
}
