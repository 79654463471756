/* Bootstrap custom */
body {
    /* $grey-600 */
    color: #6f7273;
}

main {
    background-attachment: fixed;
}

hr {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

/* Buttons */
.btn-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.btn-primary,
.btn-danger {
    color: #fff;
    text-transform: uppercase;
}

.btn-outline-primary:hover {
    color: #fff;
}

.bg-secondary {
    background-color: #ebebeb !important;
}

/* IE fix */
.fixed-top {
    left: auto;
}

.form-control::-webkit-input-placeholder {
    color: #adb5bd; /* Grey 500 */
}

.form-control:-ms-input-placeholder {
    color: #adb5bd; /* Grey 500 */
}

/* Main */
.page-p {
    min-height: 70vh;
}

/* Paddings */
@media (min-width: 1200px) {
    .mb-6 {
        margin-bottom: 4.5rem;
    }

    .page-p {
        padding-top: 2rem;
        padding-bottom: 8rem;
    }
}

@media (max-width: 1199px) {
    .page-p {
        padding-top: 1rem;
        padding-bottom: 4rem;
    }
}

/* User Login Status */
.user-login-status {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.user-login-status .dropdown-toggle:after {
    content: none;
}

.btn-user-login {
    font-size: 1.5rem;
    color: #6f7273;
}

@media screen and (max-width: 900px) {
    .user-login-status {
        justify-content: flex-start;
    }
}

/* Cards */
.main-card-title {
    height: 165px;
}

.main-card-text {
    height: 212px;
}

.card-title {
    height: 48px;
}

.card-text {
    height: 92px;
}

.card img {
    border-bottom: 8px solid #f4832e;
}

.card-img {
    height: 250px;
    background-size: cover;
    background-position: center;
    border-bottom: 8px solid #f4832e;
}

/* Navbar */
.navbar .phone {
    padding-top: 0.6rem !important;
}

.navbar .icon-phone {
    font-size: 1.5rem;
}
  
.navbar-light .navbar-nav .nav-link {
    font-weight: 600;
    text-transform: uppercase;
}

.navbar {
    padding: 0.5rem 0;
}

.navbar-text {
    padding-bottom: 0.3rem;
}

.navbar-collapse {
    margin-bottom: 14px;
}

.navbar-brand img {
    min-width: 167px;
    height: 5rem;
}

@media (min-width: 992px) {
    .navbar-light .navbar-nav .nav-link {
        border-right: 1px solid #6f7273;
    }
    .navbar-light .navbar-nav .nav-link:last-child {
        border-right: none;
    }
}
@media (max-width: 768px) {
    .navbar-brand img {
        height: 59px;
        margin-right: 0;
    }
    .navbar-brand {
        margin-right: 0;
    }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .btn-lg, .btn-group-lg > .btn {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

/* Carousel */
.carousel .slide {
    background: transparent;
}

/* Footer */
footer {
   background-color: white;
}

footer .img-logo {
    height: 55px;
}

footer hr {
    border-top: 1px solid rgba(0, 0, 0, 0.178);
}

footer .footer-link,
footer .all-rights-reserved,
footer .company-address {
    font-size: 0.9rem;
}

footer .btn.brand {
    font-size: 30px;
    color: #f4832e;
}

footer .all-rights-reserved-container {
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* End bootstrap custom */

/* Responsive Honeycomb */
.honeycomb-container {
    float: right;
    display: -ms-grid;
    display: grid;
    position: relative;
    /* -ms-grid-columns: 12.2vh 12.2vh 12.2vh;
    grid-template-columns: repeat(3, 12.2vh); */
    -ms-grid-columns: 29.5% 29.5% 29.5%;
    grid-template-columns: repeat(3, 24%);
    -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    /* grid-column-gap: 0vh;
    margin: 70px 10px; */
    grid-column-gap: 25px;
    margin: 100px -25px 0 auto;
    width: 461px;
}
  
.honeycomb-item {
    -ms-grid-column-span: 2;
    grid-column: span 2;
    width: 152px;
    padding: 0 0 176px 0;
    /* width: 13.6vh;
    padding: 0 0 15.73vh 0; */
    -webkit-transform: rotate(30deg) skewY(30deg);
        -ms-transform: rotate(30deg) skewY(30deg);
            transform: rotate(30deg) skewY(30deg);
    background: #fd005f;
    position: relative;
    overflow: hidden;
    visibility: hidden;
    margin-top: -98px;
    /* margin-top: -8.73vh; */
}

.honeycomb-item:nth-child(1){ -ms-grid-column: 1; -ms-grid-row: 1; grid-column-start: 1;}
.honeycomb-item:nth-child(2){ -ms-grid-column: 3; -ms-grid-row: 1; grid-column-start: 3;}
.honeycomb-item:nth-child(3){ -ms-grid-column: 2; -ms-grid-row: 2; grid-column-start: 2;}
.honeycomb-item:nth-child(4){ -ms-grid-column: 1; -ms-grid-row: 3; grid-column-start: 1;}
.honeycomb-item:nth-child(5){ -ms-grid-column: 3; -ms-grid-row: 3; grid-column-start: 3;}
.honeycomb-item:nth-child(6){ -ms-grid-column: 2; -ms-grid-row: 4; grid-column-start: 2;}
.honeycomb-item:nth-child(7){ -ms-grid-column: 1; -ms-grid-row: 5; grid-column-start: 1;}
.honeycomb-item:nth-child(8){ -ms-grid-column: 3; -ms-grid-row: 5; grid-column-start: 3;}
.honeycomb-item:nth-child(9){ -ms-grid-column: 2; -ms-grid-row: 6; grid-column-start: 2;}
.honeycomb-item:nth-child(10){ -ms-grid-column: 1; -ms-grid-row: 7; grid-column-start: 1;}
.honeycomb-item:nth-child(11){ -ms-grid-column: 3; -ms-grid-row: 7; grid-column-start: 3;}
.honeycomb-item:nth-child(12){ -ms-grid-column: 2; -ms-grid-row: 8; grid-column-start: 2;}
  
.comb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f4832e;
    -webkit-transform: skewY(-30deg) rotate(60deg);
        -ms-transform: skewY(-30deg) rotate(60deg);
            transform: skewY(-30deg) rotate(60deg);
    overflow: hidden;
    -webkit-transition: background-color .5s;
    -o-transition: background-color .5s;
    transition: background-color .5s;
}

.comb:hover {
    cursor: pointer;
    background-color: #e16c13;
    text-decoration: none;
}

.comb-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
    width: 100%;
    -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.comb-content .text {
    text-transform: uppercase;
    width: 85%;
    color: white;
    text-align: center;
    font-size: 0.95rem;
    /* font-size: 1.3vh; */
    line-height: 1.25;
    font-weight: 300;
}

.comb-content .icon {
    color: white;
    text-align: center;
    font-size: 2.7rem;
    /* font-size: 4.5vh; */
    line-height: 1.5;
    font-weight: 300;
}
      
.honeycomb-item * {
    visibility: visible
}

@media (max-width: 991px)  {
    .honeycomb-container {
        float: none;
        -ms-grid-columns: 29% 29% 29% 29% 29% 29% 29% 29%;
        -ms-grid-rows: 1fr 1fr 1fr;
        grid-template-columns: repeat(8, 22%);
        grid-column-gap: 3.2vw;
        width: 44vw;
        margin-top: 11%;
        margin-left: 1.8%;
    }

    .honeycomb-item {
        width: 14.4vw;
        padding: 0 0 16.6vw 0;
        margin-top: -9.15vw;
    }

    .comb-content .icon {
        font-size: 4.5vw;
    }
    .comb-content .text {
        font-size: 1.45vw;
    }

    .honeycomb-item:nth-child(1){ -ms-grid-column: 1; -ms-grid-row: 1; grid-column-start: 1;}
    .honeycomb-item:nth-child(2){ -ms-grid-column: 3; -ms-grid-row: 1; grid-column-start: 3;}
    .honeycomb-item:nth-child(3){ -ms-grid-column: 5; -ms-grid-row: 1; grid-column-start: 5;}
    .honeycomb-item:nth-child(4){ -ms-grid-column: 7; -ms-grid-row: 1; grid-column-start: 7;}
    .honeycomb-item:nth-child(5){ -ms-grid-column: 2; -ms-grid-row: 2; grid-column-start: 2;}
    .honeycomb-item:nth-child(6){ -ms-grid-column: 4; -ms-grid-row: 2; grid-column-start: 4;}
    .honeycomb-item:nth-child(7){ -ms-grid-column: 6; -ms-grid-row: 2; grid-column-start: 6;}
    .honeycomb-item:nth-child(8){ -ms-grid-column: 1; -ms-grid-row: 3; grid-column-start: 1;}
    .honeycomb-item:nth-child(9){ -ms-grid-column: 3; -ms-grid-row: 3; grid-column-start: 3;}
    .honeycomb-item:nth-child(10){ -ms-grid-column: 5; -ms-grid-row: 3; grid-column-start: 5;}
    .honeycomb-item:nth-child(11){ -ms-grid-column: 7; -ms-grid-row: 3; grid-column-start: 7;}
    .honeycomb-item:nth-child(12){ -ms-grid-column: 1; -ms-grid-row: 3; grid-column-start: 1;}
}
  
@media (max-width: 720px) and (orientation: portrait) {
    .honeycomb-container {
        float: none;
        -ms-grid-columns: 30.5% 30.5% 30.5%;
        grid-template-columns: repeat(3, 22%);
        grid-column-gap: 7.75vw;
        width: 90vw;
        margin-top: 20%;
        margin-left: 3.5%;
    }

    .honeycomb-item {
        width: 30.7vw;
        padding: 0 0 35.6vw 0;
        margin-top: -19.9vw;
    }

    .comb-content .icon {
        font-size: 8.4vw;
    }
    .comb-content .text {
        font-size: 3.1vw;
    }

    .honeycomb-item:nth-child(1){ -ms-grid-column: 1; -ms-grid-row: 1; grid-column-start: 1;}
    .honeycomb-item:nth-child(2){ -ms-grid-column: 3; -ms-grid-row: 1; grid-column-start: 3;}
    .honeycomb-item:nth-child(3){ -ms-grid-column: 2; -ms-grid-row: 2; grid-column-start: 2;}
    .honeycomb-item:nth-child(4){ -ms-grid-column: 1; -ms-grid-row: 3; grid-column-start: 1;}
    .honeycomb-item:nth-child(5){ -ms-grid-column: 3; -ms-grid-row: 3; grid-column-start: 3;}
    .honeycomb-item:nth-child(6){ -ms-grid-column: 2; -ms-grid-row: 4; grid-column-start: 2;}
    .honeycomb-item:nth-child(7){ -ms-grid-column: 1; -ms-grid-row: 5; grid-column-start: 1;}
    .honeycomb-item:nth-child(8){ -ms-grid-column: 3; -ms-grid-row: 5; grid-column-start: 3;}
    .honeycomb-item:nth-child(9){ -ms-grid-column: 2; -ms-grid-row: 6; grid-column-start: 2;}
    .honeycomb-item:nth-child(10){ -ms-grid-column: 1; -ms-grid-row: 7; grid-column-start: 1;}
    .honeycomb-item:nth-child(11){ -ms-grid-column: 3; -ms-grid-row: 7; grid-column-start: 3;}
    .honeycomb-item:nth-child(12){ -ms-grid-column: 2; -ms-grid-row: 8; grid-column-start: 2;}
}

/* Side menu honeycomb */
.side-menu .honeycomb-container {
    -ms-grid-columns: 34% 34%;
    grid-template-columns: repeat(2, 26%);
    margin: 56px 0 0 auto;
    width: 320px;
    float: none;
    /* -ms-grid-columns: 107px 107px;
    grid-template-columns: repeat(2, 107px);
    margin-top: 100px;
    width: 300px; */
}

.side-menu .honeycomb-item {
    width: 120px;
    padding: 0px 0 138px 0;
    margin-top: -76px;
}

.side-menu .comb-content .icon {
    font-size: 2.7rem;
}

.side-menu .comb-content .text {
    width: 95%;
    font-size: 0.75rem;
}

.side-menu .honeycomb-item:nth-child(1){ -ms-grid-column: 2; -ms-grid-row: 1; grid-column-start: 2; grid-row-start: 1;}
.side-menu .honeycomb-item:nth-child(2){ -ms-grid-column: 1; -ms-grid-row: 2; grid-column-start: 1; grid-row-start: 2;}
.side-menu .honeycomb-item:nth-child(3){ -ms-grid-column: 2; -ms-grid-row: 3; grid-column-start: 2; grid-row-start: 3;}
.side-menu .honeycomb-item:nth-child(4){ -ms-grid-column: 1; -ms-grid-row: 4; grid-column-start: 1; grid-row-start: 4;}
.side-menu .honeycomb-item:nth-child(5){ -ms-grid-column: 2; -ms-grid-row: 5; grid-column-start: 2; grid-row-start: 5;}
.side-menu .honeycomb-item:nth-child(6){ -ms-grid-column: 1; -ms-grid-row: 6; grid-column-start: 1; grid-row-start: 6;}
.side-menu .honeycomb-item:nth-child(7){ -ms-grid-column: 2; -ms-grid-row: 7; grid-column-start: 2; grid-row-start: 7;}
.side-menu .honeycomb-item:nth-child(8){ -ms-grid-column: 1; -ms-grid-row: 8; grid-column-start: 1; grid-row-start: 8;}
.side-menu .honeycomb-item:nth-child(9){ -ms-grid-column: 2; -ms-grid-row: 9; grid-column-start: 2; grid-row-start: 9;}
.side-menu .honeycomb-item:nth-child(10){ -ms-grid-column: 1; -ms-grid-row: 10; grid-column-start: 1; grid-row-start: 10;}
.side-menu .honeycomb-item:nth-child(11){ -ms-grid-column: 2; -ms-grid-row: 11; grid-column-start: 2; grid-row-start: 11;}

/* Hexagon */

.hexagon {
    display: inline-block;
    position: relative;
}

.hexagon .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}

/* Services */

.service-hexagon {
    width: 250px;
    height: 250px;
    flex-shrink: 0;
}

.service-hexagon .icon {
    cursor: pointer;
    font-size: 5.9rem;
    color: #f4832e;
}

.service-hexagon .hex {
    cursor: pointer;
    fill: white;
    stroke: #f4832e;
    stroke-width: 2px;
}

.service-description h4 {
    text-transform: uppercase;
    cursor: pointer;
}

/* React Scrollbar Custom - global settings*/
.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    border-radius: unset !important;
    height: 100% !important;
    top: 0 !important;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    border-radius: unset !important;
    background: #f4832e !important;
}

/* Modal */
#modal-service-more .modal-body {
    padding-top: 5rem;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    #modal-service-more .modal-body {
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

#modal-service-more .modal-body:before {
    position: absolute;
    content: '';
    left: 51px;
    right: 46px;
    bottom: 0;
    top: 487px;
    background-color: rgba(155, 155, 0, 0.25);
    border-radius: 50% / 20px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 17px 0px;
    clip: rect(20px, auto, 50px, 0);
    transform: rotate(180deg);
}

/* Modal Start Campaign */
@media (min-width: 992px) {
    #modal-start-campaign .modal-header {
        padding-left: 5rem;
        padding-top: 2rem;
    }
    
    #modal-start-campaign .modal-body {
        padding-left: 9rem;
        padding-right: 9rem;
    }

    .form-group.mb-2rem  {
        margin-bottom: 2rem;
    }
}

@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 850px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .modal-dialog-centered.modal-dialog-scrollable {
        flex-direction: initial;
        justify-content: initial;
        height: initial;
    }
    .modal-dialog-centered {
        display: flex;
        align-items: initial;
        min-height: initial;
    }
    .modal-content .modal-header .close {
        top: 0;
        right: 4%;
    }
}

/* Form Start Campaign */
.form-start-campaign input[placeholder] {
    font-style: italic;
}

/* React Select in Form Start Campaign */
.form-start-campaign .react-select__menu-list {
    max-height: 200px;
}

.form-start-campaign .react-select__placeholder {
    color: #adb5bd;
    font-style: italic;
}

.form-start-campaign .react-select__control,
.form-start-campaign .react-select__control--is-focused {
    background-color: hsl(0,2%,96%);
    background-color: #f7f2f2;
    border-radius: unset;
    border: 1px solid #ced4da;
    min-height: 38px;
}

.form-start-campaign .react-select__control--is-focused {
    box-shadow: 0px 0px 0px 4px rgba(244, 131, 46, 0.25);
    border: 1px solid rgb(245, 204, 172);
}

.form-start-campaign .react-select__control--menu-is-open {
    box-shadow: 0px 0px 0px 4px rgba(244, 131, 46, 0.25);
    border: 1px solid rgb(245, 204, 172);
}

.form-start-campaign .react-select__control--menu-is-open:hover,
.form-start-campaign .react-select__control--is-focused:hover {
    border-color: rgb(243, 210, 185);
}

.form-start-campaign .react-select__indicator-separator {
    margin: 0;
}

.form-start-campaign .react-select__indicator {
    height: 100%;
    font-size: .5rem;
    background-color: #f4832e;
    color: white;
}

.form-start-campaign .react-select__indicator:hover {
    background-color: #e16c13;
    color: white;
}

.form-start-campaign .form-group input {
    background-color: #f7f2f2;
}

/* React checkbox */
.rc-checkbox {
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    position: relative;
    line-height: 1;
    vertical-align: middle;
}

.rc-checkbox-input {
    position: absolute;
    left: 0;
    z-index: 9999;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.rc-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-width: 1px;
    border-style: solid;
    border-color: #ced4da;
    background-color: #f7f2f2;
    transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.rc-checkbox-checked .rc-checkbox-inner:after {
    transform: rotate(41deg);
    position: absolute;
    left: 5px;
    top: 0px;
    display: table;
    width: 7px;
    height: 14px;
    border: 2px solid #f4832e;
    border-top: 0;
    border-left: 0;
    content: ' ';
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-duration: 0.3s;
    animation-name: amCheckboxOut;
}

.rc-checkbox-inner:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 4px;
    top: 1px;
    display: table;
    width: 5px;
    height: 8px;
    border: 2px solid #f7f2f2;
    border-top: 0;
    border-left: 0;
    content: ' ';
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-duration: 0.3s;
    animation-name: amCheckboxOut;
}

/* React Scrollbar Custom - modal settings */
@media (min-width: 992px) {
    .modal-body .ScrollbarsCustom-Content {
        padding: 0 3rem 0 4rem !important;
    }
}

.modal-body .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    width: 2px !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .modal-body .ScrollbarsCustom.trackYVisible {
        height: 460px !important;
    }
}

.modal .hexagon-title {
    position: absolute;
    top: 69%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    line-height: 1;
    font-weight: 500;
    text-align: center;
    font-size: 1.05rem;
}

.modal-hexagon {
    position: absolute;
    top: -89px;
    left: 41%;
    z-index: 1;
    width: 180px;
    height: 180px;
}

.modal-hexagon .icon {
    top: 35%;
    font-size: 3rem;
    color: #f4832e;
}   

.modal-dialog-scrollable .modal-content {
    overflow: visible;
}

.modal-header .close {
    position: absolute;
    top: -57px;
    right: -46px;
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;

    float: right;
    font-size: 3.5rem;
    font-weight: 100;
    line-height: 1;
    color: rgb(255, 255, 255);
    text-shadow: rgb(0, 0, 0) 1px 1px 5px;
    opacity: 1;
}

@media (max-width: 768px) {
    .modal-hexagon {
        left: 29%;
    }

    .modal-header .close {
        right: -1%;
    }

    .modal .mCSB_container {
        padding: 0;
    }
}
/* End modal */

/* Reading */
.side-nav {
    position: sticky;
    top: 0;
    cursor: pointer;
}

.side-nav .nav-link.active {
    color: #f4832e;
    background-color: #ffdfc6;
}

.side-nav .nav-link:hover {
    color: #f4832e;
}

.reading-section {
    position: relative;
}

.reading-section .fa-link {
    position: absolute;
    opacity: 0;
    color: #f4832e;
    top: 7px;
    left: -25px;
    transition: opacity 0.3s ease-in-out;
}

.reading-section h2 {
    font-size: 1.7rem;
}

.reading-section h3 {
    font-size: 1.55rem;
}

.reading-section:hover .fa-link {
    opacity: 1;
    cursor: pointer;
}
/* End of Reading */

/* Utility add-ons */
hr {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

.text-indent {
    text-indent: 2rem; 
}

.format-paragraph {
    font-size: 1.15rem;
    line-height: 1.3;
    text-indent: 2rem;
    text-align: justify;
    text-justify: inter-word; 
}
.text-center {
    text-align: center;
}

.text-fade {
    position: relative;
    overflow: hidden;
}

.text-fade:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 35%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

/* .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
} */
/* End utility add-ons */