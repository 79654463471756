/* 1. Home */
.icon-arrow-down {
    cursor: pointer;
}

.icon-arrow-down[aria-expanded="true"] {
    -o-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    transform: rotateX(180deg);
    -webkit-transition: transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
}

.section-bottom h4 {
    line-height: 1.4;
}

.recommendations article {
    height: 200px;
}

.recommendations article p {
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */      
}

.logo-slider {
    background-color: white;
}

.client-logo {
    height: 80px;
    margin-left: auto;
    margin-right: auto;
}

.to-subscribe-newsletter-container {
    background: #f4832e;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.to-subscribe-newsletter {
    border-radius: 4px;
    border: 2px white solid;
}

.to-subscribe-newsletter:hover {
    border-color: white;
}

.form-subscribe-newsletter {
    background-color: #f4832e;
    padding: 15px 10px;
}

.form-subscribe-newsletter input {
    background-color: #f7f7f7;
    border: none;
    height: 35px;
    border-radius: 4px;
}

.subscribe-newsletter.btn {
    border-radius: 4px;
    border: 2px white solid;
}

.modal input.is-invalid,
.form-contact input.is-invalid,
.form-contact textarea.is-invalid {
    border-color: #f5c6cb;
}

.modal input.is-invalid:focus,
.form-contact input.is-invalid:focus,
.form-contact textarea.is-invalid:focus {
    border-color: #f5c6cb;
}

.modal .alert,
.form-contact .alert {
    border-radius: 0;
}

.modal-start-music .btn {
    font-size: 1.2rem;
    color: #f4832e;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .recommendations article {
        height: auto;
    }
    .client-logo {
        height: 60px;
    }
}

@media screen and (min-width: 991px) and (max-height: 900px) {
    .honeycomb-container {
        -ms-grid-columns: 25.2% 25.2% 25.2%;
        grid-template-columns: repeat(3, 20%);
        width: 461px;
        margin: 86px -80px 0 auto;
    }

    .honeycomb-item {
        width: 129px;
        padding: 0 0 149px 0;
        margin-top: -82px;
    }

    .comb-content .text {
        font-size: 0.85rem;
    }

    .page-p {
        padding-top: 0rem;
        padding-bottom: 4rem;
    }

    .main-title h1, .main-title h2 {
        font-size: 2.8rem;
    }
}
/* End home */

/* 2. About */
.authors {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

}

.author-title {
    margin-bottom: 1rem;
    font-size: 1.05rem;
    font-weight: 700;
    text-transform: uppercase;
}

.author-text .format-paragraph {
    line-height: 1.3;
}

.author-image .hex {
    stroke: #6f7273;
    stroke-width: 2px;
}

.articles-container .ScrollbarsCustom-Content {
    padding: 0 2rem 0 0 !important;
}

.articles-container .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    width: 5px !important;
}

@media (min-width: 769px) {
    .authors:nth-child(odd) .author-image {
        margin-right: 30px;
    }
    
    .authors:nth-child(even) .author-image {
        order: 2;
        margin-left: 30px;
    }
}

@media (max-width: 768px) {
    .authors {
        flex-wrap: wrap;
    }
}

.authors:nth-child(odd) {
    background: -moz-linear-gradient(right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60px, rgb(255, 255, 255, 1) 200px,rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60px, rgb(255, 255, 255, 1) 200px,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60px, rgb(255, 255, 255) 200px,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.authors:nth-child(even) {
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60px, rgb(255, 255, 255, 1) 200px,rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60px, rgb(255, 255, 255, 1) 200px,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60px, rgb(255, 255, 255) 200px,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.author-image {
    display: inline-block;
    height: 199px;
}

.hex {
    cursor: pointer;
    fill-opacity: 1;
    stroke: #000;
    stroke-width: 2;
}

/* 3. People */
.people-reference {
    cursor: pointer;
}

.people-reference > span {
    white-space: nowrap;
}

.people-for-us .format-paragraph {
    font-size: 1.2rem;
}

.people-image-container img {
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 900px) {
    .people-image-container {
        max-width: unset;
        overflow: hidden;
        height: 220px;
        margin: 2rem -15px;
    }

    .people-image-container img {
        max-width: unset;
        transform: translate(-8%);
    }
}

/* 4. Report */
.report-login {
    cursor: auto;
}

.report-bg-image {
    width: 100%;
    background-image: url(https://www.interkart.de/media/catalog/product/cache/3/image/8ecabcf…/p/o/pod10th058_58_europe_physical.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    height: 205px;
}

.report-hexagon {
    width: 135px;
    height: 135px;
}

.hex-content {
    cursor: pointer;
    fill: #f4832e;
    stroke: #f4832e;
    stroke-width: 4px;
}

.report-hexagon:hover .hex-content {
    fill: #e16c13;
    stroke: #e16c13;
}

.report-hexagon .hexagon-title {
    position: absolute;
    top: 76%;
    left: 51%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    line-height: 1;
    font-weight: 400;
    font-size: 1.15rem;
    color: #ffffff;
}

.report-hexagon .icon {
    cursor: pointer;
    top: 39%;
    left: 49%;
    font-size: 3.2rem;
    color: #ffffff;
}   
/* End reports */

/* 6. Blog */
#blog .jumbotron h1 {
    font-size: 2.2rem;
    font-weight: 400;
}
/* End Blog */

/* 7. Contact */
.contact-info {
    padding: 2rem 2.5rem;
}

.contact-info hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 2px solid rgba(255, 255, 255);
}

.contact-info .icon {
    font-size:  2.45rem;
    line-height: 1.2;
}

.contact-info .icon-envelope {
    font-size:  1.7rem;
}

.contact-info-title {
    font-size: 1.05rem;
    font-weight: 700;
}

.contact-info-content {
    font-size: 1.3rem;
    line-height: 1.1;
    font-weight: 300;
}

@media (max-width: 1151px) {
    .contact-info {
        padding: 2rem 1.2rem;
    }
}
/* End contact */

/* Blog */
.blog-post img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    border-radius: 3px;
}

@media (min-width: 1200px) {
    .blog-post-list {
        border: 2px solid #adb5bd;
        border-radius: 16px;
        padding: 1rem 2rem;
    }
}
/* End Blog */

/* Subscription */
.subscribe-newsletter-container {
    border: 0;
    height: 1030px;
}

@media screen and (max-width: 1060px) {
    .subscribe-newsletter-container {
        border: 0;
        height: 1230px;
    }
}

.subscribe-newsletter-long {
    background: white;
    padding: 2.5rem 2.5rem;
    border: 1px solid #f4832e;
    border-radius: 4px;
}

.subscribe-newsletter-long h1 {
    line-height: 1.5;
    font-size: 2.2rem;
}
/* End Subscription */

/* No match */
#nomatch.page-p {
    padding-top: 4rem;
}

.nomatch-link {
    color: #e16c13;
}

.nomatch-text {
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 1.2;
}

.nomatch-advice {
    font-weight: 400;
}
/* End No match */

/* Single Service */
.service-image-container {
    overflow: hidden;
}

#service .service-hexagon {
    width: 135px;
    height: 135px;
    margin-bottom: 15px;
}

#service .service-hexagon .icon {
    color: white;
    font-size: 4.4rem;
}

#service .service-hexagon .hex {
    fill: #f4832e;
    stroke: #f4832e;
    stroke-width: 2px;
}

#service .service-title {
    font-weight: 300;
}

@media screen and (min-width: 991px) {
    .service-image-container img {
        max-width: unset;
        height: 550px;
    }
}
/* End of Single Service */

/* Privacy Policy consent */
#privacy-policy .nav-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* End of Privacy Policy */